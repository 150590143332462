<template>
  <div>
    <loading-page
      :show-loading-page="showLoadingPage"
      :loading-message="loadingMessage"
    />
  </div>
</template>
<script>
import axios from 'src/app-axios';
import LoadingPage from '../dashboard/LoadingPage.vue';
import { dsAppDomainUrl } from 'src/app-globals';

export default {
    name: 'Logout',
    components: {
      'loading-page': LoadingPage,
    },

    data () {
      return {
        showLoadingPage: false,
        loadingMessage: '',
        dsDomainUrl: dsAppDomainUrl,
      };
    },
    mounted () {
      this.showLoadingPage = true;
      this.loadingMessage = 'Logging Off...';
      setTimeout(() => {
       this.logOut();
      }, 2000);
    },
    methods: {
      async logOut () {
        await axios.post('api/logout');
        this.$store.dispatch('logout').then(() => {
          window.location.href = this.dsDomainUrl + '/logout-all';
        }).catch(() => {
          window.location.href = this.dsDomainUrl + '/logout-all';
        });
      },
      clearAllCookies () {
        const cookies = document.cookie.split(';');
        cookies.forEach(cookie => {
          const cookieName = cookie.split('=')[0].trim();
          document.cookie = `${cookieName}=;`;
        });
      },
    },
};
</script>
